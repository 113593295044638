import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import store from '@/store';
import { SearchParams } from '@/models';

class SearchService {

  getSeachAdvancedResult(params: any, page: number, limit: number) {
    return axiosApiInstance.post(`${Const.api_url}advanced-search?page=${page}&limit=${limit}`, params)
  }
}

export default new SearchService
