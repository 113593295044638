









































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import SearchService from '@/services/SearchService'
import FormatDate from '@/helpers/FormatDate'
import CheckFileFormat from '@/helpers/CheckFileExtension'
import { ETargetData, ETargetSeach, SearchResult } from '@/models'
import LoadingSearch from '@/components/Loading/LoadingSearch.vue'
//@ts-ignore
// import { toHiragana, toKatakana } from 'wanakana'

@Component({ components: { LoadingSearch } })
export default class SearchPage extends Vue {
  private keyWords: string = ''
  private keyWordText: string = ''
  private searchResults: SearchResult[] = []
  private page: number = 1
  private maxPage: number = 1
  private total: number = 0
  private loading: boolean = true
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private eTargetSeach = ETargetSeach
  private eTargetData = ETargetData
  private noAvatar = require('@/assets/images/avatar-default.png')
  private avatarGroupDefault = require('@/assets/images/group/avatar_default.png')
  private ATTACH_FILE: string = 'ATTACH_FILE'
  private MESSAGE_DELETED: string = 'MESSAGE_DELETED'
  private LEAVE_GROUP_CHAT: string = 'LEAVE_GROUP_CHAT'
  private LEAVE_SYSTEM: string = 'LEAVE_SYSTEM'

  created() {
    this.getAllResult()
  }

  @Watch('$route.params.research')
  watchGetAll() {
    this.page = 1
    this.getAllResult()
    this.$bvModal.hide(this.$route.params.modalId)
  }

  /**
   * Call API to get results
   */
  getAllResult(loadMore?: boolean) {
    if (!loadMore) {
      this.searchResults = []
      this.total = -1
    }
    this.loading = true
    this.keyWords = this.$route.query.keyword_search as string
    this.keyWordText = (this.$route.query.keyword_search as string).trim()
    this.keyWordText = this.keyWordText
      .replaceAll('　', ',')
      .replaceAll(' ', ',')
      .replaceAll(/,{2,}/g, ',')
    let searchData: any = {
      target_search: this.$route.query.target_search as string,
      target_data: String(this.$route.query.target_data_string).split(','),
      keyword_search: this.keyWordText,
      time_start: this.$route.query.time_start as string,
      time_end: this.$route.query.time_end as string
    }

    if (
      searchData.target_search == ETargetSeach.SHARED_FOLDER ||
      searchData.target_search == ETargetSeach.TOPIC
    ) {
      searchData.target_group = this.$route.query.target_group as string
    }

    SearchService.getSeachAdvancedResult(searchData, this.page, this.limit)
      .then(res => {
        if (res.status === 200) {
          if (loadMore) {
            this.searchResults = [...this.searchResults, ...res.data.data]
          } else {
            this.searchResults = res.data.data
          }

          this.maxPage = res.data.last_page
          this.total = res.data.total
        }
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  /**
   * Call API for loadmore
   */
  handleLoadmore() {
    ++this.page
    this.getAllResult(true)
  }

  /**
   * Handle keywords
   *
   * Mark red for keywords
   */
  markRedKeyWord(result: SearchResult, target: ETargetData) {
    let targetData = target

    //handle content need to mark read
    let content = ''
    switch (targetData) {
      case ETargetData.TITLE:
        if (result.belong_to === ETargetSeach.CHAT) {
          return this.handleChatTitle(result)
        }
        content = result.title
        break
      case ETargetData.CONTENT:
        if (
          result.belong_to === ETargetSeach.CHAT
          // &&
          // result.content === 'chat_detail_message_deleted'
        ) {
          const text = result.content ? result.content.split(',')[0] : ''
          const name = result.content ? result.content.split(',')[1] : ''
          switch (text) {
            case this.ATTACH_FILE:
              return `<span class='text-secondary-tint-less'>${this.$t(
                'common.message.chat_no_content'
              )}</span>`
            case this.MESSAGE_DELETED:
              return `<span class='text-secondary-tint-less'>${this.$t(
                'chat.list.msg.msg_deleted'
              )}</span>`
            case this.LEAVE_GROUP_CHAT:
              return `<span class='text-secondary-tint-less'>${this.$t(
                'chat.list.msg.leave_group_chat',
                {
                  name: name
                }
              )}</span>`
            case this.LEAVE_SYSTEM:
              return `<span class='text-secondary-tint-less'>${this.$t(
                'chat.list.msg.leave_system',
                {
                  name: name
                }
              )}</span>`
            case 'chat_detail_message_deleted':
              return `<span class='text-secondary-tint-less'>${this.$t(
                'chat.list.msg.msg_deleted'
              )}</span>`
          }
        }
        content = result.content
        break
      case ETargetData.UPLOADER:
        if (!result.updater) return ''
        content = result.updater.name
        break
      default:
        break
    }

    //check shared folder comment
    if (
      result.belong_to === ETargetSeach.SHARED_FOLDER &&
      targetData === ETargetData.COMMENT
    ) {
      content = result.content
    }

    //check topic comment
    if (
      result.belong_to === ETargetSeach.TOPIC &&
      !result.is_topic &&
      targetData === ETargetData.COMMENT
    ) {
      content = result.content
    }

    //validate content
    if (!content) {
      return this.handleNonContent(result)
    }

    //convert content to raw text
    const text = this.removeHTML(content)

    //mark read for target data only
    if (
      !String(this.$route.query.target_data_string)
        .split(',')
        .includes(String(targetData))
    )
      return text

    //find index of keyword
    const keyWords = this.keyWordText.split(',')
    let replaceText = text
    keyWords.forEach(kw => {
      replaceText = this.findKeyword(replaceText, kw)
    })

    return replaceText
      .replaceAll('φ', '<span class="text-danger">')
      .replaceAll('▽', '</span>')
  }

  /**
   * findKeyword
   */
  findKeyword(text: string, kw: string) {
    let keyWordIndex = 0

    try {
      keyWordIndex = text.search(kw)
    } catch {}

    if (
      !text.includes(kw.toLowerCase()) &&
      !text.includes(kw.toUpperCase())
      // !text.includes(toHiragana(kw)) &&
      // !text.includes(toKatakana(kw))
    )
      return text
    //hanlde display text
    const startSlice = keyWordIndex - 5
    const endSlice = keyWordIndex + 200
    const contentSliced = text.slice(startSlice < 0 ? 0 : startSlice, endSlice)
    const prefix = startSlice < 0 ? '' : '...'
    const suffix = endSlice > text.length ? '' : '...'

    //replace keyword red
    const contentDisplay = prefix + contentSliced + suffix
    const replaceText = contentDisplay
      .replaceAll(kw.toLowerCase(), `φ${kw.toLowerCase()}▽`)
      .replaceAll(kw.toUpperCase(), `φ${kw.toUpperCase()}▽`)
    // .replaceAll(toHiragana(kw), `φ${toHiragana(kw)}▽`)
    // .replaceAll(toKatakana(kw), `φ${toKatakana(kw)}▽`)

    return replaceText
  }

  /**
   * Hanle content null
   */
  handleNonContent(result: SearchResult) {
    const nonContent = (string: string) => {
      return `<span class="text-secondary-tint-less">${string}</span>`
    }
    switch (result.belong_to) {
      case ETargetSeach.TOPIC:
        return nonContent(this.$t('common.message.topic_no_content') as string)
      case ETargetSeach.CALENDAR:
        return ''
      case ETargetSeach.CHAT:
        return nonContent(this.$t('chat.list.msg.message_deleted') as string)
      case ETargetSeach.SHARED_FOLDER:
        return nonContent(this.$t('common.message.file_no_content') as string)
      default:
        return ''
    }
  }

  /**
   * Handle chat title
   */
  handleChatTitle(chat: SearchResult) {
    if (!chat.is_direct_chat) return chat.name

    if (!chat.group_chat_avatar.length)
      return chat.name + this.$t('chat.chat_with')

    const names = chat.title ? chat.title.split(',') : []
    if (names.length > 2) {
      return (
        names.join(`${this.$t('common.suffix.san')}${this.$t('chat.comma')}`) +
        this.$t('chat.chat_group_name_with', {
          other_user_count: names.length - 2
        })
      )
    } else {
      return (
        names
          .map(name => `${name}${this.$t('common.suffix.san')}`)
          .join(`${this.$t('chat.comma')}`) + this.$t('chat.chat_with')
      )
    }
  }

  /**
   * Get inner text only
   */
  removeHTML(text: string) {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = text
    return tmp.textContent || tmp.innerText || ''
  }

  /**
   * Helper format date
   */
  formatDateWithDays(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  /**
   * For file
   *
   * Get file format
   */
  getFileFormat(path: string) {
    const pathSplit = path.split('.')
    const fileExtension = pathSplit[pathSplit.length - 1]
    return CheckFileFormat.getFileFormat(fileExtension)
  }

  /**
   * For file
   *
   * Get icon name
   */
  getIconName(path: string) {
    const pathSplit = path.split('.')
    const fileExtension = pathSplit[pathSplit.length - 1]
    return CheckFileFormat.getIconClassName(
      CheckFileFormat.getFileFormat(fileExtension)
    )
  }

  /**
   * Research - open modal search
   */
  backToSearch() {
    this.$bvModal.show('modal-search-header')
  }

  /**
   * Jupm to detail page
   */
  jumpToDetail(currentSelected: SearchResult) {
    switch (currentSelected.belong_to) {
      case ETargetSeach.TOPIC:
        if (!this.$route.query.target_group) {
          return ({
            name: 'common-topic-new-feed-topic-detail',
            params: { topicId: String(currentSelected.topic_id) },
            query: !currentSelected.is_topic
              ? { commentSearchId: String(currentSelected.cmt_id) }
              : {}
          })
        } else {
          return ({
            name: 'group-new-feed-topic-detail',
            params: {
              groupId: String(this.$route.query.target_group),
              topicId: String(currentSelected.topic_id)
            },
            query: !currentSelected.is_topic
              ? { commentSearchId: String(currentSelected.cmt_id) }
              : {}
          })
        }
      case ETargetSeach.CALENDAR:
        return ({
          name: 'calendar-preview',
          params: { id: String(currentSelected.id) }
        })
      case ETargetSeach.CHAT:
        return ({
          name: 'chat-detail',
          params: { groupChatId: String(currentSelected.group_chat_id) },
          query: { message: String(currentSelected.id) }
        })
      case ETargetSeach.SHARED_FOLDER:
        if (!this.$route.query.target_group) {
          return ({
            name: 'common-topic-shared-folder-file-detail',
            params: { fileId: String(currentSelected.id) }
          })
        } else {
          return ({
            name: 'group-shared-folder-file-detail',
            params: {
              groupId: String(this.$route.query.target_group),
              fileId: String(currentSelected.id)
            }
          })
        }
      default:
        return {}
    }
  }
}
